<template>
	<div class="orderPay">
		<el-card class="box-card father-card" shadow="never">
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<el-form-item label="按月份查询">
						<el-date-picker v-model="form.pickerValue" type="month" placeholder="选择月份"
							value-format="yyyy-MM">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="机构名称">
						<el-select v-model="form.corpId" placeholder="请选择所属机构" filterable clearable
							@change="selectChange" @clear="search">
							<el-option v-for="(item, index) in orderCompanyList" :key="index" :label="item.companyName"
								:value="item.uuid"></el-option>
						</el-select>
					</el-form-item>
					<!-- 按钮区域 -->
					<el-form-item>
						<el-button type="primary" @click="getList">查询</el-button>
					</el-form-item>
					<!-- 	<el-form-item>
						<el-button type="primary" @click="">导出</el-button>
					</el-form-item> -->
				</el-form>
			</el-row>
			<el-row class="tab">
				<!-- 表格区域 -->
				<el-row :gutter="20">
					<el-col>
						<div class="table-title">
							<div style="margin-left: 25%;">本月数据</div>
							<div style="margin-right: 25%;">上月数据</div>
						</div>
						<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
							highlight-current-row stripe border :header-cell-style="rowClass" show-summary>
							<el-table-column prop="companyName" label="机构名称" align="center"></el-table-column>
							<el-table-column prop="jiesMoney" label="结算金额" align="center"></el-table-column>
							<el-table-column prop="noJiesMoney" label="未结算金额" align="center"></el-table-column>
							<el-table-column prop="heji" label="合计" align="center"></el-table-column>
							<el-table-column prop="kaiPiaoMoney" label="开票金额" align="center"></el-table-column>
							<el-table-column prop="xian" label="|" align="center" width="30"></el-table-column>
							<el-table-column prop="upperjiesMoney" label="结算金额" align="center"></el-table-column>
							<el-table-column prop="upperNoJiesMoney" label="未结算金额" align="center"></el-table-column>
							<el-table-column prop="upperHeji" label="合计" align="center"></el-table-column>
							<el-table-column prop="upperkaiPiaoMoney" label="开票金额" align="center"></el-table-column>
						</el-table>
					</el-col>
					<!-- <el-col :span="12">
						<div class="table-title">本月数据</div>
						<el-table ref="multipleTable" :data="tableData2" tooltip-effect="dark" style="width: 100%"
							highlight-current-row stripe border :header-cell-style="rowClass" show-summary>

							<el-table-column prop="" label="结算金额" align="center"></el-table-column>
							<el-table-column prop="" label="未结算金额" align="center"></el-table-column>
							<el-table-column prop="" label="合计" align="center"></el-table-column>
						</el-table>
					</el-col> -->
				</el-row>
			</el-row>
		</el-card>
	</div>
</template>

<script>
import store from '@/store/index.js'

export default {
  name: 'orderPay',
  components: {},
  data () {
    return {
      form: {
        pickerValue: '',
        unitValue: '',
        corpId: ''
      }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderForm: {},
      orderFormRules: {
        dispatchItem: [{
          required: true,
          message: '请输入项目名称',
          trigger: 'blur'
        }]
      }, // 编辑资料表单验证规则
      isdisable: true,
      tableData: [],
      orderCompanyList: [], // 机构列表
      userName: '',
      companyId: ''
    }
  },
  created () {
    this.userName = window.sessionStorage.getItem('userName')
    this.companyId = window.sessionStorage.getItem('companyId')

    // this.companyId = this.userName === '黄玉玲' ? '' : window.sessionStorage.getItem('companyId')
    this.getorderCompany()
  },
  methods: {
    async getorderCompany (e) { // 获取机构名称
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope',
        {
          headers: {
            uuid: store.state.order_company || sessionStorage.getItem('order_company'),
            AUTH_TYPE: 'company'
          }
        })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.orderCompanyList = res.data
    },
    // 给表格头部设定背景色
    rowClass ({
      row,
      rowIndex
    }) {
      return 'background:#e4eaec'
    },
    async getYueBao () {
      const {
        data: res
      } = await this.$http.post('/billService/InfoPacketController/getYueBao', {
        settlementTime: this.form.pickerValue,
        // corpId: '',
        companyId: this.companyId
      })
      console.log(res, '月报列表')
      this.tableData = res.data
      this.tableData.forEach(item => {
        item.heji = Number(item.jiesMoney) + Number(item.noJiesMoney)
        item.upperHeji = Number(item.upperjiesMoney) + Number(item.upperNoJiesMoney)
        item.xian = '|'
      })
    },
    getList () {
      if (!this.form.pickerValue) {
        return this.$message.error('请先选择查询月份')
      }
      this.getYueBao()
    },
    selectChange (value) {
      this.form.corpId = value
      this.companyId = value
    },
    search () {
      console.log(this.selectForm.corpId)
      if (!this.selectForm.corpId) {
        this.companyId = ''
      }
      this.getOrderTable()
    }
  }

}
</script>
<style lang="less" scoped>
	.orderPay {
		width: 100%;
		height: 100%;

		.father-card {
			.btns {
				margin-bottom: 10px;
			}

			.tab {
				.el-pagination {
					margin-bottom: 0.25rem;
					margin-top: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}

				.order-dialog {
					/deep/ .el-form {
						.subtitle {
							color: #0097fe;
							font-size: 14px;
							font-weight: 700;
							margin-bottom: 20px;
							padding-left: 6px;
							border-left: 5px solid #0097fe;
						}
					}
				}

				.table-title {
					display: flex;
					justify-content: space-between;
					margin-bottom: 40px;
					font-size: 24px;
					color: #a6a6a6;
				}
			}
		}
	}
</style>
